import { useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DownloadIcon from '@mui/icons-material/Download';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { push } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import CaseList from './CaseList';
import CaseReport from './CaseReport';
import CaseReportSettings from './CaseReportSettings';
import { logout } from '../features/user/userSlice';
import { useTranslation } from 'react-i18next';
import Dashboard from './Dashboard';
import TMDashboard from './TMDashboard';
import TMReport from './TMReport';
import { createStyles, makeStyles } from '@mui/styles';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import httpService from '../services/httpService';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      backgroundColor: `${theme.palette.grey[300]} !important`,
    },
  }),
);

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Home() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  let location = useLocation();
  const currentUserRole = useAppSelector((state) => state.user?.role);
  const [downloadTablesAnchorEl, setDownloadAnchorEl] = useState<null | HTMLElement>(null);
  const downloadTablesOpen = Boolean(downloadTablesAnchorEl);
  const handleClickDownloadTables = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDownloadAnchorEl(event.currentTarget);
  };
  const handleCloseDownloadTables = () => {
    setDownloadAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onLogOut = () => {
    dispatch(logout());
    localStorage.removeItem('token');
  };

  const downloadFile = async (path: string) => {
    try {
      setDownloading(true);
      const response = await httpService.get(`/${path}/download`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${path}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      alert(error);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {t('pages.home.title')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {currentUserRole === 'pm' && (
            <ListItem
              button
              key={'Dashboard'}
              onClick={() => dispatch(push(`/dashboard`))}
              className={location.pathname.startsWith('/dashboard') ? classes.active : ''}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={t('pages.home.dashboard')} />
            </ListItem>
          )}
            <ListItem
              button
              key={'Dashboard'}
              onClick={() => dispatch(push(`/tm_reports`))}
              className={location.pathname.startsWith('/tm_reports') ? classes.active : ''}>
              <ListItemIcon>
                <SimCardDownloadIcon />
              </ListItemIcon>
              <ListItemText primary="TM Reports" />
            </ListItem>
          <ListItem
            button
            key={'TM Dashboard'}
            onClick={() => dispatch(push(`/tm_dashboard`))}
            className={location.pathname.startsWith('/tm_dashboard') ? classes.active : ''}>
            <ListItemIcon>
              <BackupTableIcon />
            </ListItemIcon>
            <ListItemText primary="TM Dashboard" />
          </ListItem>

          <ListItem
            button
            key={'Cases'}
            onClick={() => dispatch(push(`/cases`))}
            className={location.pathname.startsWith('/cases') ? classes.active : ''}>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary={t('pages.home.cases')} />
          </ListItem>
        </List>
        <Divider />
        <List>
          {!downloading && (
            <ListItem key="Download">
              <ListItemIcon>
                <IconButton
                  id="download-tables-button"
                  aria-controls={downloadTablesOpen ? 'download-tables-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={downloadTablesOpen ? 'true' : undefined}
                  onClick={handleClickDownloadTables}>
                  <DownloadIcon />
                </IconButton>
                <Menu
                  id="download-tables-menu"
                  anchorEl={downloadTablesAnchorEl}
                  open={downloadTablesOpen}
                  onClose={handleCloseDownloadTables}
                  MenuListProps={{
                    'aria-labelledby': 'download-tables-button',
                  }}>
                  <MenuItem
                    onClick={() => {
                      handleCloseDownloadTables();
                      downloadFile('companies-database');
                    }}>
                    {t('pages.home.companiesDatabaseTable')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseDownloadTables();
                      downloadFile('contacts-database');
                    }}>
                    {t('pages.home.contactsDatabaseTable')}
                  </MenuItem>
                </Menu>
              </ListItemIcon>
              <ListItemText primary={t('pages.home.download')} />
            </ListItem>
          )}
          {downloading && (
            <ListItem key="Downloading">
              <CircularProgress />
            </ListItem>
          )}
        </List>
        <div style={{ flexGrow: 1 }}></div>
        <List>
          <ListItem button key={'Logout'} onClick={onLogOut}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={t('pages.home.logout')} />
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Switch>
          <Redirect exact from={'/'} to={`/cases`} />
          <Route path={`/dashboard`} component={Dashboard} />
          <Route path={`/tm_dashboard`} component={TMDashboard} />
          <Route path={`/tm_reports`} component={TMReport} />
          <Route exact path={`/cases`} component={CaseList} />
          <Route exact path={`/cases/:id/report`} component={CaseReport} />
          <Route exact path={`/cases/:id/report/settings`} component={CaseReportSettings} />
        </Switch>
      </Box>
    </Box>
  );
}
