import React, { useState  } from 'react';
import "../assets/css/main.css"
import "../assets/css/bootstrap.css"
import httpService from '../services/httpService';
import $ from 'jquery';

import {
  CircularProgress
} from '@mui/material';


const TMReport = () => {
  const [loading, setLoading] = useState(false);
  
    const downloadMonthlyReport = async () => {
        try {
            var year = $("#monthly-year").val()!;
            var month = $("#monthly-month").val()!;
            setLoading(true);
            var api_url="/tm_report/download?year="+year+"&month="+month
            const response = await httpService.get(api_url, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var path = "monthly_report_"+year+"_"+month
            link.setAttribute('download', `${path}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            console.error(error);
            alert("Cannot load the data.");
            setLoading(false);
        }
    }

  var years = [];
  var current_year = (new Date()).getFullYear();
  var current_month = (new Date()).getMonth() + 1; 
  for(var year = current_year; year > 2018; year --){
    years.push(year);
  }
  var months = []
  for(var i=1;i<13;i++){
    months.push(i);
  }

  var default_year = current_year;
  var default_month = current_month;
  if(current_month === 1){
    default_month = 12;
    default_year = default_year-1;
  }
  return (
    <>
      <div id="container">
         <div className="tm_report">
            <h1>TM績效</h1>
            <div className="filter_tm_report">
                <select name="monthly-year" id="monthly-year">
                    {years.map((year) =>
                                <option value={year.toString()} selected={year === default_year}>{year}</option>
                    )}
                </select>
                <select name="monthly-month" id="monthly-month">
                    {months.map((month) =>
                                <option value={month.toString()} selected={month === default_month}>{month}</option>

                    )}        
                </select>
            </div>
            <button className="btn button button-link" id="download-monthly" onClick={downloadMonthlyReport}>下載</button>
            {loading && <CircularProgress />}   
        </div>
      </div>
    </>
  );
};

export default TMReport;
